<template>
  <div class="layout layout--with-header">
    <layout-header />
    <router-view />
    <esmp-loader
      v-show="!loading.app && loading.page"
      size="large"
      fix
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

const LayoutHeader = () => import('@/layouts/header/');

export default {
  name: 'LayoutWithHeader',
  components: {
    LayoutHeader,
  },
  computed: {
    ...mapState('system', ['loading']),
  },
};
</script>
